<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-10' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <p>Amazon-Accounting Overview</p>
            
            <button @click="exportReport()" :class="firstLoading && reportListing.length > 0 ? 'text-white bg-primary2 cursor-pointer' : 'cursor-not-allowed bg-gray-300 tet-text2 filter opacity-60'" style="letter-spacing: 1px;" class="px-2 cust_card heading-6 mr-8 flex items-center">
              <!-- <i class="fas fa-file-export h-5 w-5 text-white"></i> -->
              Export to Excel
            </button>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" >
              <button id="toggle" class="bg-gray-600 hover:bg-gray-700 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div style="overflow-y: auto; white-space: nowrap;" class="table_containder relative" v-if="firstLoading && reportListing.length > 0">
            <div class="text-text2  min-w-min heaer_row border p-2 border-gray1 font-bold flex heading-6 py-1 items-center " style="position: sticky;top: 0;background: white;" >
              <div class="dates_col  flex items-center">
                <span class="cursor-pointer" @click="sortByColumn(0, titleObj[0].sort, titleObj[0].value)">{{titleObj[0].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[0].value) && titleObj[0].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[0].value) && !titleObj[0].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center">
                <span class="" >{{titleObj[1].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[1].value) && titleObj[1].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[1].value) && !titleObj[1].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="id_col  flex items-center">
                <span class="" >{{titleObj[2].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[2].value) && titleObj[2].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[2].value) && !titleObj[2].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="id2_col  flex items-center">
                <span class="" >{{titleObj[3].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[3].value) && titleObj[3].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[3].value) && !titleObj[3].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="mid_col  flex items-center">
                <span class="" >{{titleObj[4].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[4].value) && titleObj[4].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[4].value) && !titleObj[4].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="mid_col  flex items-center">
                <span class="" >{{titleObj[5].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[5].value) && titleObj[5].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[5].value) && !titleObj[5].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="mid_col  flex items-center ">
                <span class="" >{{titleObj[6].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[6].value) && titleObj[6].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[6].value) && !titleObj[6].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center ">
                <span class="" >{{titleObj[7].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[7].value) && titleObj[7].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[7].value) && !titleObj[7].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="avgSell_col  flex items-center ">
                <span class="" >{{titleObj[8].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[8].value) && titleObj[8].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[8].value) && !titleObj[8].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="avgSell_col  flex items-center ">
                <span class="" >{{titleObj[9].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[9].value) && titleObj[9].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[9].value) && !titleObj[9].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="avgSell_col  flex items-center ">
                <span class="" >{{titleObj[10].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[10].value) && titleObj[10].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[10].value) && !titleObj[10].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="avgSell2_col  flex items-center ">
                <span class="" >{{titleObj[12].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[12].value) && titleObj[12].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[12].value) && !titleObj[12].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[13].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[13].value) && titleObj[13].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[13].value) && !titleObj[13].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[14].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[14].value) && titleObj[14].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[14].value) && !titleObj[14].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[15].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[15].value) && titleObj[15].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[15].value) && !titleObj[15].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[16].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[16].value) && titleObj[16].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[16].value) && !titleObj[16].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[17].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[17].value) && titleObj[17].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[17].value) && !titleObj[17].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[18].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[18].value) && titleObj[18].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[18].value) && !titleObj[18].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[19].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[19].value) && titleObj[19].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[19].value) && !titleObj[19].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[20].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[20].value) && titleObj[20].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[20].value) && !titleObj[20].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[21].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[21].value) && titleObj[21].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[21].value) && !titleObj[21].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[22].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[22].value) && titleObj[22].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[22].value) && !titleObj[22].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[23].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[23].value) && titleObj[23].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[23].value) && !titleObj[23].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[24].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[24].value) && titleObj[24].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[24].value) && !titleObj[24].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[25].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[25].value) && titleObj[25].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[25].value) && !titleObj[25].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[26].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[26].value) && titleObj[26].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[26].value) && !titleObj[26].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="vsmall_col  flex items-center rightText">
                <span class="" >{{titleObj[27].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[27].value) && titleObj[27].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[27].value) && !titleObj[27].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
            </div>
            <div :style="`max-height:` +  cardHight + `px; !important`" class="">
              <div class="min-w-min flex border-r border-l border-b p-2 border-gray1 bg-white text-sm items-start py-1 cursor-pointer hover:bg-gray-50" v-for="(dataA, indexA) in reportListing" :key="indexA" style="place-items:flex-start;">
                <div class="dates_col">
                  <span class="heading-6-1 text-text1" v-if="dataA.dateAndTime !== ''">{{dataA.dateAndTime | dateTimeFilter}}</span>
                  <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                </div>
                <div>
                  <div v-for="(dataB, indexB) in dataA.orderDetails" :key="indexB" class="flex py-0.5" >
                    <div class="small_col">
                      <span class="heading-6-1 text-text1"  v-if="dataB.type !== ''">{{dataB.type}}</span>
                      <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                    </div>
                    <div class="id_col">
                      <span class="heading-6-1 text-text1"  v-if="dataB.orderId !== ''">{{dataB.orderId}}</span>
                      <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                    </div>
                    <div>
                      <div v-for="(dataC, indexC) in dataB.skuDetail" :key="indexC" class="flex" :class="{'bg-gray-100': indexC % 2}">
                        <div class="id2_col">
                          <span class="heading-6-1 text-text1"  v-if="dataC.sku !== ''">{{dataC.sku}}</span>
                          <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                        </div>
                        <div>
                          <div v-for="(dataD, indexD) in dataC.descriptionsDetail" :key="indexD" class="flex" >
                            <div class="mid_col">
                              <span class="heading-6-1 text-text1"  v-if="dataD.description !== ''">{{dataD.description}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="mid_col">
                              <span class="heading-6-1 text-text1"  v-if="dataD.marketplace !== ''">{{dataD.marketplace}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="mid_col ">
                              <span class="heading-6-1 text-text1"  v-if="dataD.accountType !== ''">{{dataD.accountType}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="small_col ">
                              <span class="heading-6-1 text-text1"  v-if="dataD.fullfillment !== ''">{{dataD.fullfillment}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="avgSell_col ">
                              <span class="heading-6-1 text-text1"  v-if="dataD.orderCity !== ''">{{dataD.orderCity}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="avgSell_col ">
                              <span class="heading-6-1 text-text1"  v-if="dataD.orderState !== ''">{{dataD.orderState}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="avgSell_col ">
                              <span class="heading-6-1 text-text1"  v-if="dataD.orderPostal !== ''">{{dataD.orderPostal}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="avgSell2_col ">
                              <span class="heading-6-1 text-text1"  v-if="dataD.taxCollectionModel !== ''">{{dataD.taxCollectionModel}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.quantity !== ''">{{dataD.quantity}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.productSales !== ''">{{dataD.productSales | amountFormaterWithToFix}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.productSalesTax !== ''">{{dataD.productSalesTax | amountFormaterWithToFix}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.shippingCredits !== ''">{{dataD.shippingCredits | amountFormaterWithToFix}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.shippingCreditsTax !== ''">{{dataD.shippingCreditsTax | amountFormaterWithToFix}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.giftWrapCredits !== ''">{{dataD.giftWrapCredits | amountFormaterWithToFix}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.giftWrapCreditsTax !== ''">{{dataD.giftWrapCreditsTax | amountFormaterWithToFix}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.promotionalRebates !== ''">{{dataD.promotionalRebates | amountFormaterWithToFix}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.promotionalRebatesTax !== ''">{{dataD.promotionalRebatesTax | amountFormaterWithToFix}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.marketplaceWithheldTax !== ''">{{dataD.marketplaceWithheldTax | amountFormaterWithToFix}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.sellingFees !== ''">{{dataD.sellingFees | amountFormaterWithToFix}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.fbaFees !== ''">{{dataD.fbaFees | amountFormaterWithToFix}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.otherTransactionFees !== ''">{{dataD.otherTransactionFees | amountFormaterWithToFix}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.other !== ''">{{dataD.other | amountFormaterWithToFix}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                            <div class="vsmall_col rightText">
                              <span class="heading-6-1 text-text1"  v-if="dataD.total !== ''">{{dataD.total | amountFormaterWithToFix}}</span>
                              <span class="heading-6-1 text-text1 pl-4" v-else>--</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="layout m-2" v-if="reportListing.length === 0 && firstLoading">
            <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <div>
                <p class=" font-medium heading-4">No record found.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box col-span-2 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" :style="!isFilterOpen ? 'display: none' : ''">
          <GlobalFilter :callFrom="'Amazon'" :callParent="'accounting'" @closeFilter="closeFilter" :isFilterOpen="isFilterOpen"/>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import GlobalFilter from '@/View/Admin/Components/filterWithoutSelection.vue'
import axios from 'axios'
import {downloadFile} from '@/utils/common.js'
import moment from 'moment'
import API from '@/api/App.js'
export default {
  components: {
    GlobalFilter,
  },
  data () {
    return {
      isFilterOpen: true,
      reportListing: [],
      titleObj: [
        {title: 'Date/Time', icon: '', value: 'dateAndTime', sort: false},
        {title: 'Type', icon: '', value: 'amazonSKU', sort: true},
        {title: 'Order ID', icon: '', value: 'asin', sort: true},
        {title: 'Sku', icon: '', value: 'amazonDesc', sort: true},
        {title: 'Description', icon: '', value: 'sageItemCode', sort: true},
        {title: 'Marketplace', icon: '', value: 'sageItemDesc', sort: true},
        {title: 'Account Type', icon: '', value: 'qtySold', sort: true},
        {title: 'FulFillment', icon: '', value: 'avgSellingPrice', sort: true},
        {title: 'Order City', icon: '', value: 'stdCOGS', sort: true},
        {title: 'Order State', icon: '', value: 'avgMktFee', sort: true},
        {title: 'Order Postal', icon: '', value: 'avgFbaFee', sort: true},
        {title: 'Payment Method', icon: '', value: 'avgAdCost', sort: true},
        {title: 'Tax Collection Model', icon: '', value: 'avgInboundFee', sort: true},
        {title: 'Quantity', icon: '', value: 'avgUnitNet', sort: true},
        {title: 'Product Sales', icon: '', value: 'totalSales', sort: true},
        {title: 'Product Sales Tax', icon: '', value: 'totalCogs', sort: true},
        {title: 'Shipping Credits', icon: '', value: 'totalFbaFee', sort: true},
        {title: 'Shipping Credits Tax', icon: '', value: 'totalMktFee', sort: true},
        {title: 'Gift Wrap Credits', icon: '', value: 'totalAdCost', sort: true},
        {title: 'Gift Wrap Credits Tax', icon: '', value: 'totalInboundFees', sort: true},
        {title: 'Promotional Rebates', icon: '', value: 'avgCogs', sort: true},
        {title: 'Promotional Rebates Tax', icon: '', value: 'lastCogs', sort: true},
        {title: 'Marketplace Withheld Tax', icon: '', value: 'totalNet', sort: true},
        {title: 'Selling Fees', icon: '', value: 'totalNet', sort: true},
        {title: 'FBA Fees', icon: '', value: 'totalNet', sort: true},
        {title: 'Other Transaction Fees', icon: '', value: 'totalNet', sort: true},
        {title: 'Other', icon: '', value: 'totalNet', sort: true},
        {title: 'Total', icon: '', value: 'totalNet', sort: true},
      ],
      pagination: {
        sortBy: 'dateAndTime',
        descending: false
      },
      filterObject: {
        startDate: '',
        endDate: '',
        minDate: '',
        maxDate: '',
      },
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      showCustomDatepicker: false,
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.cardHight = window.innerHeight - 280
    this.mainHeight = window.innerHeight - 150
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
    console.log('this.defaultStartDate', this.defaultStartDate)
    console.log('this.defaultEndDate', this.defaultEndDate)
  },
  watch: {
    filterObject: {
      handler () {
        console.log('filterObject', this.filterObject)
      },
      deep: true
    },
  },
  methods: {
    closeFilter (data) {
      console.log('data', data)
      this.filterObject.startDate = data.startDate
      this.filterObject.endDate = data.endDate
      this.getListing()
    },
    openCloseFilter () {
      if (this.isFilterOpen) {
        this.isFilterOpen = false
      } else {
        this.isFilterOpen = true
      }
      console.log('openCloseFilter')
    },
    getListing () {
      this.firstLoading = false
      console.log('this.filterObject.startDate 123 123', this.filterObject.startDate)
      console.log('this.filterObject.endDate 123 123', this.filterObject.endDate)
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      API.AccountingAmazonReport(
        utcSTART,
        utcEND,
        '',
        false,
        response => {
          this.reportListing = response.Data === null ? [] : response.Data
          console.log(response.Data)
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.firstLoading = true
        },
        error => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    sortByColumn (i, val1, val2) {
      console.log('i', i)
      console.log('val1', val1)
      console.log('val2', val2)
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      console.log('this.pagination.descending', this.pagination.descending)
      if (this.pagination.descending) {
        console.log('click 1')
        this.reportListing.sort(function (a, b) {
          console.log('a', a)
          console.log('b', b)
          if (val2 === 'dateAndTime') {
            var nameA = a.dateAndTime
            var nameB = b.dateAndTime
          }
          console.log('nameA-------------------- 1', nameA)
          console.log('nameB-------------------- 1', nameB)
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        console.log('click 2')
        this.reportListing.reverse(function (a, b) {
          if (val2 === 'dateAndTime') {
            var nameA = a.dateAndTime
            var nameB = b.dateAndTime
          }
          console.log('nameA-------------------- 2', nameA)
          console.log('nameB-------------------- 2', nameB)
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
    },
    async exportReport () {
      if (this.firstLoading && this.reportListing.length > 0) {
        let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
        let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
        let day1 = moment(this.filterObject.startDate).format("DD-MM-YY")
        let day2 = moment(this.filterObject.endDate).format("DD-MM-YY")
        let Time = 'Amazon Accounting Overview-' + day1 + '-To-' + day2
        let data = new FormData()
        data.append('from', utcSTART)
        data.append('to', utcEND)
        data.append('searchValue', '')
        data.append('export', true)
        await this.downloadAttachment('api/v1/analytics/amazon/accounting/report', `${Time}` + '.xlsx', data)
      }
    },
    async downloadAttachment (subUrl, fileName, formData) {
      let url = this.$store.getters.liveServerUrl + subUrl
      // If Want To cancel request
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      let originalFileName = fileName
      this.$store.dispatch('CancelDownloadRequest', source)
      // Show loader while downloading
      this.$store.dispatch('SetDownloadLoader', true)
      // Calling Api and waiting for response
      let response = await downloadFile(formData, url, source)
      // Checking for status if 200 == success
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        console.log(window.URL.createObjectURL(new Blob([response.data])))
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        // CLosing loader and reseting text in loader
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>

.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.dates_col {
  min-width: 120px !important;
  width: 120px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.total_col {
  min-width: 82px !important;
  width: 82px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.vsmall_col {
  min-width: 94px !important;
  width: 94px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.avgSell_col {
  min-width: 90px !important;
  width: 90px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.avgSell2_col {
  min-width: 140px !important;
  width: 140px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.mid_col {
  min-width: 120px !important;
  width: 120px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  min-width: 84px !important;
  width: 84px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.id_col {
  min-width: 160px !important;
  width: 160px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.id2_col {
  min-width: 208px !important;
  width: 208px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}


</style>
