<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white">
        <div class="mb-2">
          <TabCompo @setNewTabActive="tabsSet" :tabsList="tabs" />
        </div>
        <div v-show="activeIndex === 0">
          <listing></listing>
        </div>
        <div v-show="activeIndex === 1">
          <reconciliation></reconciliation>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import TabCompo from '@/View/components/tabs.vue'
import reconciliation from './components/reconciliation.vue';
import listing from './components/listing.vue';
export default {
  components: {
    TabCompo,
    reconciliation,
    listing
  },
  data () {
    return {
      tabs: [
        {name: 'Report', active: true},
        {name: 'Reconciliation', active: false},
      ],
      activeIndex: 0,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    // this.$root.$on('setNewTabActive', (index) => {
    //   this.tabsSet(index)
    // })
    document.title = 'Accounting ◾ Amazon'
  },
  watch: {
  },
  methods: {
    tabsSet (index) {
      this.tabs.forEach((element, i) => {
          if (index === i) {
              element.active = true
              this.activeIndex = i
          } else {
              element.active = false
          }
      });
    },
  },
  beforeDestroy () {
    this.$root.$off("setNewTabActive");
  }
}
</script>
<style scoped>
</style>
